<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="创建会员"
    ></el-page-header>
    <div class="select-container">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="会员名" class="name" prop="name">
          <el-input style="width: 30%" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <!-- 合同编号，改为input -->
        <el-form-item label="合同编号" prop="contractNo" class="contractNo">
          <!-- <el-input style="width: 30%" v-model="ruleForm.contractNo"></el-input> -->
          <el-select
            v-model="ruleForm.contractNo"
            filterable
            remote
            reserve-keyword
            placeholder="查询合同"
            :remote-method="remoteMethodVIP"
            :loading="loading"
            style="width: 25%; margin-right: 2%"
          >
            <el-option
              v-for="item in ruleForm.vipOptions"
              :key="item.vipValue"
              :label="`${item.No}`"
              :value="item.cardNo"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同费" class="contractPrice">
          <el-input
            disabled
            style="width: 30%"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            v-model="ruleForm.contractPrice"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员卡额度" class="vipLimit" prop="vipLimit">
          <el-input
            style="width: 30%"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            v-model="ruleForm.vipLimit"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员电话" prop="phonenum">
          <el-input
            style="width: 30%"
            v-model="ruleForm.phonenum"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员编号" prop="vipNo">
          <el-input style="width: 30%" v-model="ruleForm.vipNo"></el-input>
        </el-form-item>
        <el-form-item label="登陆密码" prop="password">
          <el-input
            style="width: 30%"
            show-password
            v-model="ruleForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="开卡时间" class="date">
          <el-input
            style="width: 30%"
            disabled
            v-model="ruleForm.date"
          ></el-input>
        </el-form-item>
        <el-form-item label="开卡人:" class="creator">
          <el-input
            style="width: 80%"
            disabled
            v-model="ruleForm.creator"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员状态">
          <el-radio class="radio1" v-model="ruleForm.radio" label="1"
            >正常</el-radio
          >
          <el-radio class="radio2" v-model="ruleForm.radio" label="2"
            >冻结</el-radio
          >
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="备注"
            v-model="ruleForm.textarea"
            class="textarea"
            style="width: 30%"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="create">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即创建</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import moment from "moment";
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data() {
    return {
      ruleForm: {
        name: "",
        loading: false,
        vipOptions: [],
        vipValue: "",
        value: "",
        // vipName: '',
        contractNo: "", // 先有合同，才能创建会员
        contractPrice: "", // 合同费
        vipLimit: "",
        phonenum: "",
        vipNo: "",
        date: "",
        radio: "1",
        creator: "",
        textarea: "",
        password: "",
        // eslint-disable-next-line no-dupe-keys
        creator: "",
        store: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入会员名称", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        contractNo: [
          { required: true, message: "请输入合同编号", trigger: "blur" },
        ],
        vipLimit: [
          { required: true, message: "请输入会员卡额度", trigger: "blur" },
        ],
        phonenum: [
          { required: true, message: "请输入会员电话", trigger: "blur" },
        ],
        vipNo: [{ required: true, message: "请输入会员编号", trigger: "blur" }],
        password: [
          {
            required: true,
            min: 4,
            max: 12,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    const selectStoreKey = true;
    const storeKey = this.userKey;
    axios
      .post(this.GLOBAL.api + "/store", { selectStoreKey, storeKey })
      .then((res) => {
        this.store = res.data.result;
      });
  },
  created() {
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    this.ruleForm.date = moment().format("YYYY-MM-DD HH:mm:ss");
    // 创建会员的管理员名称，从本地获取
    this.ruleForm.creator = localStorage.getItem("user_id");
  },
  watch: {
    ruleForm: {
      handler: function () {
        const contractOption = this.ruleForm.vipOptions[0];
        this.ruleForm.contractNo = contractOption.No;
        this.ruleForm.contractPrice = +contractOption.contractMoney;
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    // search contract.
    remoteMethodVIP(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          const cardNo = query;
          const dimSearch = true;
          let userKey = this.userKey;
          this.ruleForm.vipOptions = axios
            .post(this.GLOBAL.api + "/creat/contract", {
              dimSearch,
              cardNo,
              userKey,
            })
            .then((item) => {
              return (this.ruleForm.vipOptions = item.data);
            });
          this.loading = false;
        }, 200);
      } else {
        this.ruleForm.vipOptions = [];
      }
    },
    submitForm(formName) {
      const ctrate_vip = this.ruleForm;
      const create = true;
      let userKey = this.userKey;
      let storeTitle = this.store[0].title;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(this.GLOBAL.api + "/creat/vip", {
              create,
              ctrate_vip,
              userKey,
              storeTitle,
            })
            .then((res) => {
              if (res.data.statusCode === 201) {
                this.$message({
                  message: "会员编号重复",
                  type: "warning",
                });
              } else if (res.data.statusCode === 200) {
                this.$confirm("创建成功，是否继续创建会员?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.reload();
                  })
                  .catch(() => {
                    this.$router.push("/member/list");
                  });
              } else {
                this.$message({
                  message: "系统繁忙请刷新重试",
                  type: "warning",
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
  position: relative;
  margin-top: 2%;
}
.box-card {
  width: 100%;
  height: 100%;
}
.creator {
  position: absolute;
  font-weight: bold;
  left: 60%;
  top: 0%;
}
</style>